/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CouponBrandImage from './CouponBrandImage';
import CouponCompany from './CouponCompany';
import CouponContact from './CouponContact';
import Timestamp from './Timestamp';





/**
* The CouponBrand model module.
* @module model/CouponBrand
* @version 1.1.72
*/
export default class CouponBrand {
    /**
    * Constructs a new <code>CouponBrand</code>.
    * @alias module:model/CouponBrand
    * @class
    * @param id {Number} 
    * @param name {String} 
    * @param displayName {String} 
    */

    constructor(id, name, displayName) {
        


        this['id'] = id;this['name'] = name;this['displayName'] = displayName;

        
    }

    /**
    * Constructs a <code>CouponBrand</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/CouponBrand} obj Optional instance to populate.
    * @return {module:model/CouponBrand} The populated <code>CouponBrand</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CouponBrand();

            
            
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('couponCompany')) {
                obj['couponCompany'] = CouponCompany.constructFromObject(data['couponCompany']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('displayName')) {
                obj['displayName'] = ApiClient.convertToType(data['displayName'], 'String');
            }
            if (data.hasOwnProperty('priority')) {
                obj['priority'] = ApiClient.convertToType(data['priority'], 'Number');
            }
            if (data.hasOwnProperty('contact')) {
                obj['contact'] = CouponContact.constructFromObject(data['contact']);
            }
            if (data.hasOwnProperty('image')) {
                obj['image'] = CouponBrandImage.constructFromObject(data['image']);
            }
        }
        return obj;
    }

    /**
    * @member {String} createdAt
    */
    createdAt = undefined;
    /**
    * @member {String} updatedAt
    */
    updatedAt = undefined;
    /**
    * @member {String} deletedAt
    */
    deletedAt = undefined;
    /**
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {module:model/CouponCompany} couponCompany
    */
    couponCompany = undefined;
    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {String} displayName
    */
    displayName = undefined;
    /**
    * @member {Number} priority
    */
    priority = undefined;
    /**
    * @member {module:model/CouponContact} contact
    */
    contact = undefined;
    /**
    * @member {module:model/CouponBrandImage} image
    */
    image = undefined;








}


