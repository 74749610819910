/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import InlineResponse2001 from '../model/InlineResponse2001';

/**
* Oembed service.
* @module api/OembedApi
* @version 1.1.72
*/
export default class OembedApi {

    /**
    * Constructs a new OembedApi. 
    * @alias module:api/OembedApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * 埋め込みタグの取得
     * 各 SNS の oEmbedAPI から埋め込み用の HTML を取得して返します。(対応 SNS は Facebook, Instagram, Twitter のみ) 
     * @param {Object} opts Optional parameters
     * @param {String} opts.url 埋め込みたい SNS の投稿 URL  対応している URL Schema  Facebook - &#x60;https://www.facebook.com/_*_/posts/_*&#x60;  Instagram - &#x60;http://www.instagram.com/p/_*&#x60;  Twitter - &#x60;https://twitter.com/_*_/status/_*&#x60; 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2001} and HTTP response
     */
    getEmbedHtmlWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'url': opts['url']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2', 'OutsourcedArticleTool'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = InlineResponse2001;

      return this.apiClient.callApi(
        '/oembed', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 埋め込みタグの取得
     * 各 SNS の oEmbedAPI から埋め込み用の HTML を取得して返します。(対応 SNS は Facebook, Instagram, Twitter のみ) 
     * @param {Object} opts Optional parameters
     * @param {String} opts.url 埋め込みたい SNS の投稿 URL  対応している URL Schema  Facebook - &#x60;https://www.facebook.com/_*_/posts/_*&#x60;  Instagram - &#x60;http://www.instagram.com/p/_*&#x60;  Twitter - &#x60;https://twitter.com/_*_/status/_*&#x60; 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2001}
     */
    getEmbedHtml(opts) {
      return this.getEmbedHtmlWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
