/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';


/**
* Enum class GameLocation.
* @enum {}
* @readonly
*/
export default class GameLocation {
    
        /**
         * value: "popular"
         * @const
         */
        popular = "popular";

    
        /**
         * value: "recommended"
         * @const
         */
        recommended = "recommended";

    

    /**
    * Returns a <code>GameLocation</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/GameLocation} The enum <code>GameLocation</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}


