/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The RealtimeReportGoogleAnalyticsItem model module.
* @module model/RealtimeReportGoogleAnalyticsItem
* @version 1.1.72
*/
export default class RealtimeReportGoogleAnalyticsItem {
    /**
    * Constructs a new <code>RealtimeReportGoogleAnalyticsItem</code>.
    * @alias module:model/RealtimeReportGoogleAnalyticsItem
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>RealtimeReportGoogleAnalyticsItem</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/RealtimeReportGoogleAnalyticsItem} obj Optional instance to populate.
    * @return {module:model/RealtimeReportGoogleAnalyticsItem} The populated <code>RealtimeReportGoogleAnalyticsItem</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RealtimeReportGoogleAnalyticsItem();

            
            
            if (data.hasOwnProperty('totalItems')) {
                obj['totalItems'] = ApiClient.convertToType(data['totalItems'], 'Number');
            }
            if (data.hasOwnProperty('quota')) {
                obj['quota'] = ApiClient.convertToType(data['quota'], Object);
            }
            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [Object]);
            }
        }
        return obj;
    }

    /**
    * @member {Number} totalItems
    */
    totalItems = undefined;
    /**
    * @member {Object} quota
    */
    quota = undefined;
    /**
    * @member {Array.<Object>} items
    */
    items = undefined;








}


