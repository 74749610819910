/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The NewPanel model module.
* @module model/NewPanel
* @version 1.1.72
*/
export default class NewPanel {
    /**
    * Constructs a new <code>NewPanel</code>.
    * @alias module:model/NewPanel
    * @class
    * @param name {String} 
    */

    constructor(name) {
        


        this['name'] = name;

        
    }

    /**
    * Constructs a <code>NewPanel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/NewPanel} obj Optional instance to populate.
    * @return {module:model/NewPanel} The populated <code>NewPanel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewPanel();

            
            
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('positionName')) {
                obj['positionName'] = ApiClient.convertToType(data['positionName'], 'String');
            }
            if (data.hasOwnProperty('image')) {
                obj['image'] = ApiClient.convertToType(data['image'], 'String');
            }
            if (data.hasOwnProperty('anchorUrl')) {
                obj['anchorUrl'] = ApiClient.convertToType(data['anchorUrl'], 'String');
            }
            if (data.hasOwnProperty('anchorArticleId')) {
                obj['anchorArticleId'] = ApiClient.convertToType(data['anchorArticleId'], 'Number');
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = ApiClient.convertToType(data['order'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {String} positionName
    */
    positionName = undefined;
    /**
    * @member {String} image
    */
    image = undefined;
    /**
    * @member {String} anchorUrl
    */
    anchorUrl = undefined;
    /**
    * @member {Number} anchorArticleId
    */
    anchorArticleId = undefined;
    /**
    * @member {Number} order
    */
    order = undefined;








}


