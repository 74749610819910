/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ArticleDetail from '../model/ArticleDetail';
import ArticleSummary from '../model/ArticleSummary';
import ArticleUpdateValues from '../model/ArticleUpdateValues';
import ArticlesBulkUpdateValues from '../model/ArticlesBulkUpdateValues';
import InlineResponse200 from '../model/InlineResponse200';
import NewArticle from '../model/NewArticle';
import Tag from '../model/Tag';

/**
* Article service.
* @module api/ArticleApi
* @version 1.1.72
*/
export default class ArticleApi {

    /**
    * Constructs a new ArticleApi. 
    * @alias module:api/ArticleApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * 外部委託記事から一括更新する
     * @param {module:model/ArticlesBulkUpdateValues} articlesBulkUpdateValues 更新する記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse200} and HTTP response
     */
    bulkUpdateFromOutsourceArticleWithHttpInfo(articlesBulkUpdateValues) {
      let postBody = articlesBulkUpdateValues;

      // verify the required parameter 'articlesBulkUpdateValues' is set
      if (articlesBulkUpdateValues === undefined || articlesBulkUpdateValues === null) {
        throw new Error("Missing the required parameter 'articlesBulkUpdateValues' when calling bulkUpdateFromOutsourceArticle");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = InlineResponse200;

      return this.apiClient.callApi(
        '/articles/bulkUpdateFromOutsource', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 外部委託記事から一括更新する
     * @param {module:model/ArticlesBulkUpdateValues} articlesBulkUpdateValues 更新する記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse200}
     */
    bulkUpdateFromOutsourceArticle(articlesBulkUpdateValues) {
      return this.bulkUpdateFromOutsourceArticleWithHttpInfo(articlesBulkUpdateValues)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事の削除
     * @param {Number} articleId 記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteArticleWithHttpInfo(articleId) {
      let postBody = null;

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling deleteArticle");
      }


      let pathParams = {
        'articleId': articleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/articles/{articleId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事の削除
     * @param {Number} articleId 記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteArticle(articleId) {
      return this.deleteArticleWithHttpInfo(articleId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事の本文に関連するタグの削除
     * @param {Number} articleId 記事の ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteArticleDescriptionTagWithHttpInfo(articleId, tagId) {
      let postBody = null;

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling deleteArticleDescriptionTag");
      }

      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling deleteArticleDescriptionTag");
      }


      let pathParams = {
        'articleId': articleId,
        'tagId': tagId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/articles/{articleId}/descriptionTags/{tagId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事の本文に関連するタグの削除
     * @param {Number} articleId 記事の ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteArticleDescriptionTag(articleId, tagId) {
      return this.deleteArticleDescriptionTagWithHttpInfo(articleId, tagId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事のタイトルに関連するタグの削除
     * @param {Number} articleId 記事の ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteArticleTitleTagWithHttpInfo(articleId, tagId) {
      let postBody = null;

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling deleteArticleTitleTag");
      }

      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling deleteArticleTitleTag");
      }


      let pathParams = {
        'articleId': articleId,
        'tagId': tagId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/articles/{articleId}/titleTags/{tagId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事のタイトルに関連するタグの削除
     * @param {Number} articleId 記事の ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteArticleTitleTag(articleId, tagId) {
      return this.deleteArticleTitleTagWithHttpInfo(articleId, tagId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事一覧をデータCSVとしてダウンロード
     * 記事一覧をデータCSVとしてダウンロードできます。
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'String'} and HTTP response
     */
    downloadArticlesCsvWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = 'String';

      return this.apiClient.callApi(
        '/articles/downloadCsv', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事一覧をデータCSVとしてダウンロード
     * 記事一覧をデータCSVとしてダウンロードできます。
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'String'}
     */
    downloadArticlesCsv(opts) {
      return this.downloadArticlesCsvWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事の詳細
     * @param {Number} articleId 記事の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.outputType 記事本文の HTML 出力タイプ (default to tinyMce)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ArticleDetail} and HTTP response
     */
    getArticleWithHttpInfo(articleId, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling getArticle");
      }


      let pathParams = {
        'articleId': articleId
      };
      let queryParams = {
        'outputType': opts['outputType']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = ArticleDetail;

      return this.apiClient.callApi(
        '/articles/{articleId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事の詳細
     * @param {Number} articleId 記事の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.outputType 記事本文の HTML 出力タイプ (default to tinyMce)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ArticleDetail}
     */
    getArticle(articleId, opts) {
      return this.getArticleWithHttpInfo(articleId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事の本文に関連するタグの一覧
     * @param {Number} articleId 記事の ID
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Tag>} and HTTP response
     */
    getArticleDescriptionTagsWithHttpInfo(articleId, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling getArticleDescriptionTags");
      }


      let pathParams = {
        'articleId': articleId
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [Tag];

      return this.apiClient.callApi(
        '/articles/{articleId}/descriptionTags', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事の本文に関連するタグの一覧
     * @param {Number} articleId 記事の ID
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Tag>}
     */
    getArticleDescriptionTags(articleId, opts) {
      return this.getArticleDescriptionTagsWithHttpInfo(articleId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事のタイトルに関連するタグの一覧
     * @param {Number} articleId 記事の ID
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Tag>} and HTTP response
     */
    getArticleTitleTagsWithHttpInfo(articleId, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling getArticleTitleTags");
      }


      let pathParams = {
        'articleId': articleId
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [Tag];

      return this.apiClient.callApi(
        '/articles/{articleId}/titleTags', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事のタイトルに関連するタグの一覧
     * @param {Number} articleId 記事の ID
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Tag>}
     */
    getArticleTitleTags(articleId, opts) {
      return this.getArticleTitleTagsWithHttpInfo(articleId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事の一覧
     * - 状態（&#x60;status&#x60;）が「公開（&#x60;publish&#x60;）」以外の場合は非公開の記事です。   クライアントが TRILL Admin 以外の場合、非公開の記事は含まれません。 - 公開日時（&#x60;publishDatetime&#x60;）が未来の場合は予約中の記事です。   クライアントが TRILL Admin 以外の場合、予約中の記事は含まれません。 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ArticleSummary>} and HTTP response
     */
    getArticlesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [ArticleSummary];

      return this.apiClient.callApi(
        '/articles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事の一覧
     * - 状態（&#x60;status&#x60;）が「公開（&#x60;publish&#x60;）」以外の場合は非公開の記事です。   クライアントが TRILL Admin 以外の場合、非公開の記事は含まれません。 - 公開日時（&#x60;publishDatetime&#x60;）が未来の場合は予約中の記事です。   クライアントが TRILL Admin 以外の場合、予約中の記事は含まれません。 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ArticleSummary>}
     */
    getArticles(opts) {
      return this.getArticlesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事の更新
     * @param {Number} articleId 記事の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/ArticleUpdateValues} opts.articleUpdateValues 更新する記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ArticleDetail} and HTTP response
     */
    patchArticleWithHttpInfo(articleId, opts) {
      opts = opts || {};
      let postBody = opts['articleUpdateValues'];

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling patchArticle");
      }


      let pathParams = {
        'articleId': articleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = ArticleDetail;

      return this.apiClient.callApi(
        '/articles/{articleId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事の更新
     * @param {Number} articleId 記事の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/ArticleUpdateValues} opts.articleUpdateValues 更新する記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ArticleDetail}
     */
    patchArticle(articleId, opts) {
      return this.patchArticleWithHttpInfo(articleId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事の追加
     * @param {module:model/NewArticle} newArticle 追加する記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ArticleDetail} and HTTP response
     */
    postArticleWithHttpInfo(newArticle) {
      let postBody = newArticle;

      // verify the required parameter 'newArticle' is set
      if (newArticle === undefined || newArticle === null) {
        throw new Error("Missing the required parameter 'newArticle' when calling postArticle");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = ArticleDetail;

      return this.apiClient.callApi(
        '/articles', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事の追加
     * @param {module:model/NewArticle} newArticle 追加する記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ArticleDetail}
     */
    postArticle(newArticle) {
      return this.postArticleWithHttpInfo(newArticle)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事の復元
     * @param {Number} articleId 記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putArticleWithHttpInfo(articleId) {
      let postBody = null;

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling putArticle");
      }


      let pathParams = {
        'articleId': articleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/articles/{articleId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事の復元
     * @param {Number} articleId 記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putArticle(articleId) {
      return this.putArticleWithHttpInfo(articleId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事の本文に関連するタグの追加
     * @param {Number} articleId 記事の ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putArticleDescriptionTagWithHttpInfo(articleId, tagId) {
      let postBody = null;

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling putArticleDescriptionTag");
      }

      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling putArticleDescriptionTag");
      }


      let pathParams = {
        'articleId': articleId,
        'tagId': tagId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/articles/{articleId}/descriptionTags/{tagId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事の本文に関連するタグの追加
     * @param {Number} articleId 記事の ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putArticleDescriptionTag(articleId, tagId) {
      return this.putArticleDescriptionTagWithHttpInfo(articleId, tagId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 記事のタイトルに関連するタグの追加
     * @param {Number} articleId 記事の ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putArticleTitleTagWithHttpInfo(articleId, tagId) {
      let postBody = null;

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling putArticleTitleTag");
      }

      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling putArticleTitleTag");
      }


      let pathParams = {
        'articleId': articleId,
        'tagId': tagId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/articles/{articleId}/titleTags/{tagId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 記事のタイトルに関連するタグの追加
     * @param {Number} articleId 記事の ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putArticleTitleTag(articleId, tagId) {
      return this.putArticleTitleTagWithHttpInfo(articleId, tagId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
