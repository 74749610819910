/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The MediumItemUpdateValues model module.
* @module model/MediumItemUpdateValues
* @version 1.1.72
*/
export default class MediumItemUpdateValues {
    /**
    * Constructs a new <code>MediumItemUpdateValues</code>.
    * @alias module:model/MediumItemUpdateValues
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>MediumItemUpdateValues</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/MediumItemUpdateValues} obj Optional instance to populate.
    * @return {module:model/MediumItemUpdateValues} The populated <code>MediumItemUpdateValues</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MediumItemUpdateValues();

            
            
            if (data.hasOwnProperty('mediumId')) {
                obj['mediumId'] = ApiClient.convertToType(data['mediumId'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('hotFactor')) {
                obj['hotFactor'] = ApiClient.convertToType(data['hotFactor'], 'Number');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('autoPublish')) {
                obj['autoPublish'] = ApiClient.convertToType(data['autoPublish'], 'Boolean');
            }
            if (data.hasOwnProperty('autoPublishCpUpdate')) {
                obj['autoPublishCpUpdate'] = ApiClient.convertToType(data['autoPublishCpUpdate'], 'Boolean');
            }
            if (data.hasOwnProperty('retentionPeriod')) {
                obj['retentionPeriod'] = ApiClient.convertToType(data['retentionPeriod'], 'Number');
            }
            if (data.hasOwnProperty('ignoreCopyrightWarning')) {
                obj['ignoreCopyrightWarning'] = ApiClient.convertToType(data['ignoreCopyrightWarning'], 'Boolean');
            }
            if (data.hasOwnProperty('shannonSubmissionAllowed')) {
                obj['shannonSubmissionAllowed'] = ApiClient.convertToType(data['shannonSubmissionAllowed'], 'Boolean');
            }
            if (data.hasOwnProperty('classifyCategory')) {
                obj['classifyCategory'] = ApiClient.convertToType(data['classifyCategory'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * @member {Number} mediumId
    */
    mediumId = undefined;
    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * メディア単位の HOT 係数（記事の HOT 係数の計算に使われる）
    * @member {Number} hotFactor
    */
    hotFactor = undefined;
    /**
    * @member {String} url
    */
    url = undefined;
    /**
    * 取り込んだ記事を自動で公開状態にするかどうか
    * @member {Boolean} autoPublish
    */
    autoPublish = undefined;
    /**
    * CP修正による記事保留を無視
    * @member {Boolean} autoPublishCpUpdate
    */
    autoPublishCpUpdate = undefined;
    /**
    * 記事の保持期限（日単位）
    * @member {Number} retentionPeriod
    */
    retentionPeriod = undefined;
    /**
    * 著作権情報が含まれて無い場合の警告を無視するかどうか
    * @member {Boolean} ignoreCopyrightWarning
    */
    ignoreCopyrightWarning = undefined;
    /**
    * Shannon 連携が許可されているメディアの設定かどうか
    * @member {Boolean} shannonSubmissionAllowed
    */
    shannonSubmissionAllowed = undefined;
    /**
    * カテゴリ自動分類を行うメディアの設定かどうか
    * @member {Boolean} classifyCategory
    */
    classifyCategory = undefined;








}


