/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import NewMangasPopularCreator from '../model/NewMangasPopularCreator';
import PopularCreators from '../model/PopularCreators';

/**
* MangasPopularCreator service.
* @module api/MangasPopularCreatorApi
* @version 1.1.72
*/
export default class MangasPopularCreatorApi {

    /**
    * Constructs a new MangasPopularCreatorApi. 
    * @alias module:api/MangasPopularCreatorApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * 人気クリエイターの一覧
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PopularCreators} and HTTP response
     */
    getPopularCreatorsWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = PopularCreators;

      return this.apiClient.callApi(
        '/mangas/popularCreators', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 人気クリエイターの一覧
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PopularCreators}
     */
    getPopularCreators() {
      return this.getPopularCreatorsWithHttpInfo()
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 人気クリエイターの追加
     * @param {module:model/NewMangasPopularCreator} newMangasPopularCreator 追加する人気クリエイターの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PopularCreators} and HTTP response
     */
    postPopularCreatorWithHttpInfo(newMangasPopularCreator) {
      let postBody = newMangasPopularCreator;

      // verify the required parameter 'newMangasPopularCreator' is set
      if (newMangasPopularCreator === undefined || newMangasPopularCreator === null) {
        throw new Error("Missing the required parameter 'newMangasPopularCreator' when calling postPopularCreator");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = PopularCreators;

      return this.apiClient.callApi(
        '/mangas/popularCreators', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 人気クリエイターの追加
     * @param {module:model/NewMangasPopularCreator} newMangasPopularCreator 追加する人気クリエイターの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PopularCreators}
     */
    postPopularCreator(newMangasPopularCreator) {
      return this.postPopularCreatorWithHttpInfo(newMangasPopularCreator)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
