/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import RecommendedArticleCover from './RecommendedArticleCover';





/**
* The NewRecommendedArticle model module.
* @module model/NewRecommendedArticle
* @version 1.1.72
*/
export default class NewRecommendedArticle {
    /**
    * Constructs a new <code>NewRecommendedArticle</code>.
    * @alias module:model/NewRecommendedArticle
    * @class
    * @param articleId {Number} 
    * @param target {module:model/NewRecommendedArticle.TargetEnum} 
    * @param order {Number} 
    * @param title {String} 
    * @param cover {module:model/RecommendedArticleCover} 
    * @param publishDatetime {String} 
    */

    constructor(articleId, target, order, title, cover, publishDatetime) {
        


        this['articleId'] = articleId;this['target'] = target;this['order'] = order;this['title'] = title;this['cover'] = cover;this['publishDatetime'] = publishDatetime;

        
    }

    /**
    * Constructs a <code>NewRecommendedArticle</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/NewRecommendedArticle} obj Optional instance to populate.
    * @return {module:model/NewRecommendedArticle} The populated <code>NewRecommendedArticle</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewRecommendedArticle();

            
            
            if (data.hasOwnProperty('articleId')) {
                obj['articleId'] = ApiClient.convertToType(data['articleId'], 'Number');
            }
            if (data.hasOwnProperty('target')) {
                obj['target'] = ApiClient.convertToType(data['target'], 'String');
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = ApiClient.convertToType(data['order'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('cover')) {
                obj['cover'] = RecommendedArticleCover.constructFromObject(data['cover']);
            }
            if (data.hasOwnProperty('publishDatetime')) {
                obj['publishDatetime'] = ApiClient.convertToType(data['publishDatetime'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Number} articleId
    */
    articleId = undefined;
    /**
    * @member {module:model/NewRecommendedArticle.TargetEnum} target
    */
    target = undefined;
    /**
    * @member {Number} order
    */
    order = undefined;
    /**
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {module:model/RecommendedArticleCover} cover
    */
    cover = undefined;
    /**
    * @member {String} publishDatetime
    */
    publishDatetime = undefined;






    /**
    * Allowed values for the <code>target</code> property.
    * @enum {String}
    * @readonly
    */
    static TargetEnum = {
    
        /**
         * value: "app"
         * @const
         */
        "app": "app",
    
        /**
         * value: "web"
         * @const
         */
        "web": "web"    
    };



}


