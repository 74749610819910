/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import TagBrandInfo from './TagBrandInfo';
import Timestamp from './Timestamp';





/**
* The Tag model module.
* @module model/Tag
* @version 1.1.72
*/
export default class Tag {
    /**
    * Constructs a new <code>Tag</code>.
    * @alias module:model/Tag
    * @class
    * @param id {Number} 
    * @param name {String} 
    */

    constructor(id, name) {
        


        this['id'] = id;this['name'] = name;

        
    }

    /**
    * Constructs a <code>Tag</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Tag} obj Optional instance to populate.
    * @return {module:model/Tag} The populated <code>Tag</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Tag();

            
            
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('slug')) {
                obj['slug'] = ApiClient.convertToType(data['slug'], 'String');
            }
            if (data.hasOwnProperty('brandInfo')) {
                obj['brandInfo'] = TagBrandInfo.constructFromObject(data['brandInfo']);
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('exclude')) {
                obj['exclude'] = ApiClient.convertToType(data['exclude'], 'Boolean');
            }
            if (data.hasOwnProperty('dictionary')) {
                obj['dictionary'] = ApiClient.convertToType(data['dictionary'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * @member {String} createdAt
    */
    createdAt = undefined;
    /**
    * @member {String} updatedAt
    */
    updatedAt = undefined;
    /**
    * @member {String} deletedAt
    */
    deletedAt = undefined;
    /**
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {String} slug
    */
    slug = undefined;
    /**
    * @member {module:model/TagBrandInfo} brandInfo
    */
    brandInfo = undefined;
    /**
    * @member {String} description
    */
    description = undefined;
    /**
    * @member {Boolean} exclude
    */
    exclude = undefined;
    /**
    * @member {Boolean} dictionary
    */
    dictionary = undefined;








}


