/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import GameCategory from '../model/GameCategory';
import GameCategoryUpdateValues from '../model/GameCategoryUpdateValues';
import NewGameCategory from '../model/NewGameCategory';

/**
* GameCategory service.
* @module api/GameCategoryApi
* @version 1.1.72
*/
export default class GameCategoryApi {

    /**
    * Constructs a new GameCategoryApi. 
    * @alias module:api/GameCategoryApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * ゲームカテゴリの削除
     * @param {Number} gameCategoryId ゲームカテゴリの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteGameCategoryWithHttpInfo(gameCategoryId) {
      let postBody = null;

      // verify the required parameter 'gameCategoryId' is set
      if (gameCategoryId === undefined || gameCategoryId === null) {
        throw new Error("Missing the required parameter 'gameCategoryId' when calling deleteGameCategory");
      }


      let pathParams = {
        'gameCategoryId': gameCategoryId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/gameCategories/{gameCategoryId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ゲームカテゴリの削除
     * @param {Number} gameCategoryId ゲームカテゴリの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteGameCategory(gameCategoryId) {
      return this.deleteGameCategoryWithHttpInfo(gameCategoryId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ゲームカテゴリ一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/GameCategory>} and HTTP response
     */
    getGameCategoriesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [GameCategory];

      return this.apiClient.callApi(
        '/gameCategories', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ゲームカテゴリ一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/GameCategory>}
     */
    getGameCategories(opts) {
      return this.getGameCategoriesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ゲームカテゴリの更新
     * @param {Number} gameCategoryId ゲームカテゴリの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/GameCategoryUpdateValues} opts.gameCategoryUpdateValues 更新するゲームカテゴリの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GameCategory} and HTTP response
     */
    patchGameCategoryWithHttpInfo(gameCategoryId, opts) {
      opts = opts || {};
      let postBody = opts['gameCategoryUpdateValues'];

      // verify the required parameter 'gameCategoryId' is set
      if (gameCategoryId === undefined || gameCategoryId === null) {
        throw new Error("Missing the required parameter 'gameCategoryId' when calling patchGameCategory");
      }


      let pathParams = {
        'gameCategoryId': gameCategoryId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = GameCategory;

      return this.apiClient.callApi(
        '/gameCategories/{gameCategoryId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ゲームカテゴリの更新
     * @param {Number} gameCategoryId ゲームカテゴリの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/GameCategoryUpdateValues} opts.gameCategoryUpdateValues 更新するゲームカテゴリの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GameCategory}
     */
    patchGameCategory(gameCategoryId, opts) {
      return this.patchGameCategoryWithHttpInfo(gameCategoryId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ゲームカテゴリの追加
     * @param {module:model/NewGameCategory} newGameCategory 追加するゲームカテゴリの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GameCategory} and HTTP response
     */
    postGameCategoryWithHttpInfo(newGameCategory) {
      let postBody = newGameCategory;

      // verify the required parameter 'newGameCategory' is set
      if (newGameCategory === undefined || newGameCategory === null) {
        throw new Error("Missing the required parameter 'newGameCategory' when calling postGameCategory");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = GameCategory;

      return this.apiClient.callApi(
        '/gameCategories', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ゲームカテゴリの追加
     * @param {module:model/NewGameCategory} newGameCategory 追加するゲームカテゴリの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GameCategory}
     */
    postGameCategory(newGameCategory) {
      return this.postGameCategoryWithHttpInfo(newGameCategory)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ゲームカテゴリの復元
     * @param {Number} gameCategoryId ゲームカテゴリの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putGameCategoryWithHttpInfo(gameCategoryId) {
      let postBody = null;

      // verify the required parameter 'gameCategoryId' is set
      if (gameCategoryId === undefined || gameCategoryId === null) {
        throw new Error("Missing the required parameter 'gameCategoryId' when calling putGameCategory");
      }


      let pathParams = {
        'gameCategoryId': gameCategoryId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/gameCategories/{gameCategoryId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ゲームカテゴリの復元
     * @param {Number} gameCategoryId ゲームカテゴリの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putGameCategory(gameCategoryId) {
      return this.putGameCategoryWithHttpInfo(gameCategoryId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
