/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The MediumRelatedArticlesUpdateValuesInner model module.
* @module model/MediumRelatedArticlesUpdateValuesInner
* @version 1.1.72
*/
export default class MediumRelatedArticlesUpdateValuesInner {
    /**
    * Constructs a new <code>MediumRelatedArticlesUpdateValuesInner</code>.
    * @alias module:model/MediumRelatedArticlesUpdateValuesInner
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>MediumRelatedArticlesUpdateValuesInner</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/MediumRelatedArticlesUpdateValuesInner} obj Optional instance to populate.
    * @return {module:model/MediumRelatedArticlesUpdateValuesInner} The populated <code>MediumRelatedArticlesUpdateValuesInner</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MediumRelatedArticlesUpdateValuesInner();

            
            
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('link')) {
                obj['link'] = ApiClient.convertToType(data['link'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {String} link
    */
    link = undefined;








}


