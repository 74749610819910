/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The NewMangasPopularCreator model module.
* @module model/NewMangasPopularCreator
* @version 1.1.72
*/
export default class NewMangasPopularCreator {
    /**
    * Constructs a new <code>NewMangasPopularCreator</code>.
    * @alias module:model/NewMangasPopularCreator
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>NewMangasPopularCreator</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/NewMangasPopularCreator} obj Optional instance to populate.
    * @return {module:model/NewMangasPopularCreator} The populated <code>NewMangasPopularCreator</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewMangasPopularCreator();

            
            
            if (data.hasOwnProperty('creatorId_1')) {
                obj['creatorId_1'] = ApiClient.convertToType(data['creatorId_1'], 'Number');
            }
            if (data.hasOwnProperty('creatorId_2')) {
                obj['creatorId_2'] = ApiClient.convertToType(data['creatorId_2'], 'Number');
            }
            if (data.hasOwnProperty('creatorId_3')) {
                obj['creatorId_3'] = ApiClient.convertToType(data['creatorId_3'], 'Number');
            }
            if (data.hasOwnProperty('creatorId_4')) {
                obj['creatorId_4'] = ApiClient.convertToType(data['creatorId_4'], 'Number');
            }
            if (data.hasOwnProperty('creatorId_5')) {
                obj['creatorId_5'] = ApiClient.convertToType(data['creatorId_5'], 'Number');
            }
            if (data.hasOwnProperty('creatorId_6')) {
                obj['creatorId_6'] = ApiClient.convertToType(data['creatorId_6'], 'Number');
            }
            if (data.hasOwnProperty('creatorId_7')) {
                obj['creatorId_7'] = ApiClient.convertToType(data['creatorId_7'], 'Number');
            }
            if (data.hasOwnProperty('creatorId_8')) {
                obj['creatorId_8'] = ApiClient.convertToType(data['creatorId_8'], 'Number');
            }
            if (data.hasOwnProperty('creatorId_9')) {
                obj['creatorId_9'] = ApiClient.convertToType(data['creatorId_9'], 'Number');
            }
            if (data.hasOwnProperty('creatorId_10')) {
                obj['creatorId_10'] = ApiClient.convertToType(data['creatorId_10'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {Number} creatorId_1
    */
    creatorId_1 = undefined;
    /**
    * @member {Number} creatorId_2
    */
    creatorId_2 = undefined;
    /**
    * @member {Number} creatorId_3
    */
    creatorId_3 = undefined;
    /**
    * @member {Number} creatorId_4
    */
    creatorId_4 = undefined;
    /**
    * @member {Number} creatorId_5
    */
    creatorId_5 = undefined;
    /**
    * @member {Number} creatorId_6
    */
    creatorId_6 = undefined;
    /**
    * @member {Number} creatorId_7
    */
    creatorId_7 = undefined;
    /**
    * @member {Number} creatorId_8
    */
    creatorId_8 = undefined;
    /**
    * @member {Number} creatorId_9
    */
    creatorId_9 = undefined;
    /**
    * @member {Number} creatorId_10
    */
    creatorId_10 = undefined;








}


