/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The CouponBrandImage model module.
* @module model/CouponBrandImage
* @version 1.1.72
*/
export default class CouponBrandImage {
    /**
    * Constructs a new <code>CouponBrandImage</code>.
    * @alias module:model/CouponBrandImage
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>CouponBrandImage</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/CouponBrandImage} obj Optional instance to populate.
    * @return {module:model/CouponBrandImage} The populated <code>CouponBrandImage</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CouponBrandImage();

            
            
            if (data.hasOwnProperty('logo')) {
                obj['logo'] = ApiClient.convertToType(data['logo'], 'String');
            }
        }
        return obj;
    }

    /**
    * request:base64URL、response:ServingURL
    * @member {String} logo
    */
    logo = undefined;








}


