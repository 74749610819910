/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The SavingArticleMaximumViews model module.
* @module model/SavingArticleMaximumViews
* @version 1.1.72
*/
export default class SavingArticleMaximumViews {
    /**
    * Constructs a new <code>SavingArticleMaximumViews</code>.
    * @alias module:model/SavingArticleMaximumViews
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>SavingArticleMaximumViews</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/SavingArticleMaximumViews} obj Optional instance to populate.
    * @return {module:model/SavingArticleMaximumViews} The populated <code>SavingArticleMaximumViews</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SavingArticleMaximumViews();

            
            
            if (data.hasOwnProperty('app')) {
                obj['app'] = ApiClient.convertToType(data['app'], 'Number');
            }
            if (data.hasOwnProperty('web')) {
                obj['web'] = ApiClient.convertToType(data['web'], 'Number');
            }
            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {Number} app
    */
    app = undefined;
    /**
    * @member {Number} web
    */
    web = undefined;
    /**
    * @member {Number} total
    */
    total = undefined;








}


