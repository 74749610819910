/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import FeedDetail from '../model/FeedDetail';
import FeedSummary from '../model/FeedSummary';
import FeedUpdateValues from '../model/FeedUpdateValues';
import NewFeed from '../model/NewFeed';
import Tag from '../model/Tag';

/**
* Feed service.
* @module api/FeedApi
* @version 1.1.72
*/
export default class FeedApi {

    /**
    * Constructs a new FeedApi. 
    * @alias module:api/FeedApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * フィードの削除
     * @param {Number} feedId フィードの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteFeedWithHttpInfo(feedId) {
      let postBody = null;

      // verify the required parameter 'feedId' is set
      if (feedId === undefined || feedId === null) {
        throw new Error("Missing the required parameter 'feedId' when calling deleteFeed");
      }


      let pathParams = {
        'feedId': feedId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/feeds/{feedId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * フィードの削除
     * @param {Number} feedId フィードの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteFeed(feedId) {
      return this.deleteFeedWithHttpInfo(feedId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * フィードに関連するタグの削除（カスタムフィードの場合）
     * @param {Number} feedId フィードの ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteFeedTagWithHttpInfo(feedId, tagId) {
      let postBody = null;

      // verify the required parameter 'feedId' is set
      if (feedId === undefined || feedId === null) {
        throw new Error("Missing the required parameter 'feedId' when calling deleteFeedTag");
      }

      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling deleteFeedTag");
      }


      let pathParams = {
        'feedId': feedId,
        'tagId': tagId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/feeds/{feedId}/tags/{tagId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * フィードに関連するタグの削除（カスタムフィードの場合）
     * @param {Number} feedId フィードの ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteFeedTag(feedId, tagId) {
      return this.deleteFeedTagWithHttpInfo(feedId, tagId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * フィードの詳細
     * @param {Number} feedId フィードの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FeedDetail} and HTTP response
     */
    getFeedWithHttpInfo(feedId) {
      let postBody = null;

      // verify the required parameter 'feedId' is set
      if (feedId === undefined || feedId === null) {
        throw new Error("Missing the required parameter 'feedId' when calling getFeed");
      }


      let pathParams = {
        'feedId': feedId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = FeedDetail;

      return this.apiClient.callApi(
        '/feeds/{feedId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * フィードの詳細
     * @param {Number} feedId フィードの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FeedDetail}
     */
    getFeed(feedId) {
      return this.getFeedWithHttpInfo(feedId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * フィードに関連するタグの一覧（カスタムフィードの場合）
     * @param {Number} feedId フィードの ID
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Tag>} and HTTP response
     */
    getFeedTagsWithHttpInfo(feedId, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'feedId' is set
      if (feedId === undefined || feedId === null) {
        throw new Error("Missing the required parameter 'feedId' when calling getFeedTags");
      }


      let pathParams = {
        'feedId': feedId
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [Tag];

      return this.apiClient.callApi(
        '/feeds/{feedId}/tags', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * フィードに関連するタグの一覧（カスタムフィードの場合）
     * @param {Number} feedId フィードの ID
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Tag>}
     */
    getFeedTags(feedId, opts) {
      return this.getFeedTagsWithHttpInfo(feedId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * フィードの一覧
     * デフォルトのフィード設定情報（スクロールタブバーの全メニューを含む）を返します。ただし、クライアントが IOS または ANDROID の場合には、仕様書に記載のリクエストパラメーターに関係なくフィード全件を返し、レスポンスヘッダーの Pagination-* は付与されず、レスポンスボディーには Timestamp が含まれません。
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/FeedSummary>} and HTTP response
     */
    getFeedsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [FeedSummary];

      return this.apiClient.callApi(
        '/feeds', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * フィードの一覧
     * デフォルトのフィード設定情報（スクロールタブバーの全メニューを含む）を返します。ただし、クライアントが IOS または ANDROID の場合には、仕様書に記載のリクエストパラメーターに関係なくフィード全件を返し、レスポンスヘッダーの Pagination-* は付与されず、レスポンスボディーには Timestamp が含まれません。
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/FeedSummary>}
     */
    getFeeds(opts) {
      return this.getFeedsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * フィードの更新
     * @param {Number} feedId フィード ID
     * @param {Object} opts Optional parameters
     * @param {module:model/FeedUpdateValues} opts.feedUpdateValues 更新するフィードの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FeedDetail} and HTTP response
     */
    patchFeedWithHttpInfo(feedId, opts) {
      opts = opts || {};
      let postBody = opts['feedUpdateValues'];

      // verify the required parameter 'feedId' is set
      if (feedId === undefined || feedId === null) {
        throw new Error("Missing the required parameter 'feedId' when calling patchFeed");
      }


      let pathParams = {
        'feedId': feedId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = FeedDetail;

      return this.apiClient.callApi(
        '/feeds/{feedId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * フィードの更新
     * @param {Number} feedId フィード ID
     * @param {Object} opts Optional parameters
     * @param {module:model/FeedUpdateValues} opts.feedUpdateValues 更新するフィードの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FeedDetail}
     */
    patchFeed(feedId, opts) {
      return this.patchFeedWithHttpInfo(feedId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * フィードの追加
     * @param {module:model/NewFeed} newFeed 追加するフィードの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FeedDetail} and HTTP response
     */
    postFeedWithHttpInfo(newFeed) {
      let postBody = newFeed;

      // verify the required parameter 'newFeed' is set
      if (newFeed === undefined || newFeed === null) {
        throw new Error("Missing the required parameter 'newFeed' when calling postFeed");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = FeedDetail;

      return this.apiClient.callApi(
        '/feeds', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * フィードの追加
     * @param {module:model/NewFeed} newFeed 追加するフィードの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FeedDetail}
     */
    postFeed(newFeed) {
      return this.postFeedWithHttpInfo(newFeed)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * フィードの復元
     * @param {Number} feedId フィードの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putFeedWithHttpInfo(feedId) {
      let postBody = null;

      // verify the required parameter 'feedId' is set
      if (feedId === undefined || feedId === null) {
        throw new Error("Missing the required parameter 'feedId' when calling putFeed");
      }


      let pathParams = {
        'feedId': feedId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/feeds/{feedId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * フィードの復元
     * @param {Number} feedId フィードの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putFeed(feedId) {
      return this.putFeedWithHttpInfo(feedId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * フィードに関連するタグの追加（カスタムフィードの場合）
     * @param {Number} feedId フィードの ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putFeedTagWithHttpInfo(feedId, tagId) {
      let postBody = null;

      // verify the required parameter 'feedId' is set
      if (feedId === undefined || feedId === null) {
        throw new Error("Missing the required parameter 'feedId' when calling putFeedTag");
      }

      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling putFeedTag");
      }


      let pathParams = {
        'feedId': feedId,
        'tagId': tagId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/feeds/{feedId}/tags/{tagId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * フィードに関連するタグの追加（カスタムフィードの場合）
     * @param {Number} feedId フィードの ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putFeedTag(feedId, tagId) {
      return this.putFeedTagWithHttpInfo(feedId, tagId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
