/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import Image from './Image';
import Video from './Video';





/**
* The RecommendedArticleCover model module.
* @module model/RecommendedArticleCover
* @version 1.1.72
*/
export default class RecommendedArticleCover {
    /**
    * Constructs a new <code>RecommendedArticleCover</code>.
    * おすすめ記事（の設定枠）のカバー（通常の記事は &#x60;image&#x60; のみ、動画記事の場合は &#x60;image&#x60; と &#x60;video&#x60; 両方）
    * @alias module:model/RecommendedArticleCover
    * @class
    * @param image {module:model/Image} 
    */

    constructor(image) {
        


        this['image'] = image;

        
    }

    /**
    * Constructs a <code>RecommendedArticleCover</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/RecommendedArticleCover} obj Optional instance to populate.
    * @return {module:model/RecommendedArticleCover} The populated <code>RecommendedArticleCover</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RecommendedArticleCover();

            
            
            if (data.hasOwnProperty('image')) {
                obj['image'] = Image.constructFromObject(data['image']);
            }
            if (data.hasOwnProperty('video')) {
                obj['video'] = Video.constructFromObject(data['video']);
            }
        }
        return obj;
    }

    /**
    * @member {module:model/Image} image
    */
    image = undefined;
    /**
    * @member {module:model/Video} video
    */
    video = undefined;








}


