/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import SavingArticleMaximumViews from './SavingArticleMaximumViews';





/**
* The NewSponsor model module.
* @module model/NewSponsor
* @version 1.1.72
*/
export default class NewSponsor {
    /**
    * Constructs a new <code>NewSponsor</code>.
    * @alias module:model/NewSponsor
    * @class
    * @param name {String} 
    */

    constructor(name) {
        


        this['name'] = name;

        
    }

    /**
    * Constructs a <code>NewSponsor</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/NewSponsor} obj Optional instance to populate.
    * @return {module:model/NewSponsor} The populated <code>NewSponsor</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NewSponsor();

            
            
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('savingArticleMaximumViews')) {
                obj['savingArticleMaximumViews'] = SavingArticleMaximumViews.constructFromObject(data['savingArticleMaximumViews']);
            }
        }
        return obj;
    }

    /**
    * @member {String} name
    */
    name = undefined;
    /**
    * @member {module:model/SavingArticleMaximumViews} savingArticleMaximumViews
    */
    savingArticleMaximumViews = undefined;








}


