/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.72
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import NewPersonalityQuiz from '../model/NewPersonalityQuiz';
import PersonalityQuizDetail from '../model/PersonalityQuizDetail';
import PersonalityQuizSummary from '../model/PersonalityQuizSummary';
import PersonalityQuizUpdateValues from '../model/PersonalityQuizUpdateValues';

/**
* PersonalityQuiz service.
* @module api/PersonalityQuizApi
* @version 1.1.72
*/
export default class PersonalityQuizApi {

    /**
    * Constructs a new PersonalityQuizApi. 
    * @alias module:api/PersonalityQuizApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * 診断の削除
     * @param {Number} personalityQuizId 診断の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deletePersonalityQuizWithHttpInfo(personalityQuizId) {
      let postBody = null;

      // verify the required parameter 'personalityQuizId' is set
      if (personalityQuizId === undefined || personalityQuizId === null) {
        throw new Error("Missing the required parameter 'personalityQuizId' when calling deletePersonalityQuiz");
      }


      let pathParams = {
        'personalityQuizId': personalityQuizId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/personalityQuizzes/{personalityQuizId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 診断の削除
     * @param {Number} personalityQuizId 診断の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deletePersonalityQuiz(personalityQuizId) {
      return this.deletePersonalityQuizWithHttpInfo(personalityQuizId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 診断の詳細
     * - 診断の質問・回答（Q1 ~ Q5）と結果を返します。 
     * @param {Number} personalityQuizId 診断の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PersonalityQuizDetail} and HTTP response
     */
    getPersonalityQuizWithHttpInfo(personalityQuizId) {
      let postBody = null;

      // verify the required parameter 'personalityQuizId' is set
      if (personalityQuizId === undefined || personalityQuizId === null) {
        throw new Error("Missing the required parameter 'personalityQuizId' when calling getPersonalityQuiz");
      }


      let pathParams = {
        'personalityQuizId': personalityQuizId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = PersonalityQuizDetail;

      return this.apiClient.callApi(
        '/personalityQuizzes/{personalityQuizId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 診断の詳細
     * - 診断の質問・回答（Q1 ~ Q5）と結果を返します。 
     * @param {Number} personalityQuizId 診断の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PersonalityQuizDetail}
     */
    getPersonalityQuiz(personalityQuizId) {
      return this.getPersonalityQuizWithHttpInfo(personalityQuizId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 診断の一覧
     * 公開日時（&#x60;publishDatetime&#x60;）が未来の場合は予約中の診断です。 クライアントが TRILL Admin 以外の場合、予約中の診断は含まれません。 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @param {Number} opts.startCursor カーソル（開始点）
     * @param {Number} opts.cursorLimit カーソル（開始点）を含め、取得するアイテム数 (default to 10)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/PersonalityQuizSummary>} and HTTP response
     */
    getPersonalityQuizzesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage'],
        'startCursor': opts['startCursor'],
        'cursorLimit': opts['cursorLimit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [PersonalityQuizSummary];

      return this.apiClient.callApi(
        '/personalityQuizzes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 診断の一覧
     * 公開日時（&#x60;publishDatetime&#x60;）が未来の場合は予約中の診断です。 クライアントが TRILL Admin 以外の場合、予約中の診断は含まれません。 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @param {Number} opts.startCursor カーソル（開始点）
     * @param {Number} opts.cursorLimit カーソル（開始点）を含め、取得するアイテム数 (default to 10)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/PersonalityQuizSummary>}
     */
    getPersonalityQuizzes(opts) {
      return this.getPersonalityQuizzesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 診断の更新
     * @param {Number} personalityQuizId 診断の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/PersonalityQuizUpdateValues} opts.personalityQuizUpdateValues 更新する診断の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PersonalityQuizDetail} and HTTP response
     */
    patchPersonalityQuizWithHttpInfo(personalityQuizId, opts) {
      opts = opts || {};
      let postBody = opts['personalityQuizUpdateValues'];

      // verify the required parameter 'personalityQuizId' is set
      if (personalityQuizId === undefined || personalityQuizId === null) {
        throw new Error("Missing the required parameter 'personalityQuizId' when calling patchPersonalityQuiz");
      }


      let pathParams = {
        'personalityQuizId': personalityQuizId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = PersonalityQuizDetail;

      return this.apiClient.callApi(
        '/personalityQuizzes/{personalityQuizId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 診断の更新
     * @param {Number} personalityQuizId 診断の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/PersonalityQuizUpdateValues} opts.personalityQuizUpdateValues 更新する診断の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PersonalityQuizDetail}
     */
    patchPersonalityQuiz(personalityQuizId, opts) {
      return this.patchPersonalityQuizWithHttpInfo(personalityQuizId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 診断の追加
     * @param {module:model/NewPersonalityQuiz} newPersonalityQuiz 追加する診断の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PersonalityQuizDetail} and HTTP response
     */
    postPersonalityQuizWithHttpInfo(newPersonalityQuiz) {
      let postBody = newPersonalityQuiz;

      // verify the required parameter 'newPersonalityQuiz' is set
      if (newPersonalityQuiz === undefined || newPersonalityQuiz === null) {
        throw new Error("Missing the required parameter 'newPersonalityQuiz' when calling postPersonalityQuiz");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = PersonalityQuizDetail;

      return this.apiClient.callApi(
        '/personalityQuizzes', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 診断の追加
     * @param {module:model/NewPersonalityQuiz} newPersonalityQuiz 追加する診断の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PersonalityQuizDetail}
     */
    postPersonalityQuiz(newPersonalityQuiz) {
      return this.postPersonalityQuizWithHttpInfo(newPersonalityQuiz)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 診断の復元
     * @param {Number} personalityQuizId 診断の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putPersonalityQuizWithHttpInfo(personalityQuizId) {
      let postBody = null;

      // verify the required parameter 'personalityQuizId' is set
      if (personalityQuizId === undefined || personalityQuizId === null) {
        throw new Error("Missing the required parameter 'personalityQuizId' when calling putPersonalityQuiz");
      }


      let pathParams = {
        'personalityQuizId': personalityQuizId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/personalityQuizzes/{personalityQuizId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 診断の復元
     * @param {Number} personalityQuizId 診断の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putPersonalityQuiz(personalityQuizId) {
      return this.putPersonalityQuizWithHttpInfo(personalityQuizId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
